jQuery(document).ready(function ($) {


    /** =============================================================== **/
    /** Accessibility Improvements **/
    /** =============================================================== **/

    // Determine accessibility focus based on mouse/keyboard use
    document.body.addEventListener('mousedown', function () {
        document.body.classList.add('using-mouse');
    });
    document.body.addEventListener('keydown', function () {
        document.body.classList.remove('using-mouse');
    });


    /** =============================================================== **/
    /** Init Input masks **/
    /** =============================================================== **/

    $(".phone-mask").inputmask({
        "mask": "(999) 999-9999"
    });

    $(".date-mask").inputmask({
        alias: "mm/dd/yyyy",
        clearIncomplete: true,
        yearrange: {
            minyear: 1900,
            maxyear: 2000
        }
    });

    $(".zip-mask").inputmask({
        "mask": "99999",
        "placeholder": "",
    });


    /** =============================================================== **/
    /** Handle sticky menu on scroll **/
    /** =============================================================== **/

    $(window).on("scroll", function () {
        $("header").toggleClass("pin", $(window).scrollTop() >= 40);
    });
    $(window).trigger("scroll");

    /** =============================================================== **/
    /** Scroll to id **/
    /** =============================================================== **/

    $(".scrollto").click(function (event) {
        event.preventDefault();

        var defaultAnchorOffset = 70;

        var anchor = $(this).attr('target');

        var anchorOffset = $('#' + anchor).attr('data-scroll-offset');
        if (!anchorOffset)
            anchorOffset = defaultAnchorOffset;

        $('html,body').animate({
            scrollTop: $('#' + anchor).offset().top - anchorOffset
        }, 200);
    });

    /** =============================================================== **/
    /** Menu toogler on mobile hamburger class change **/
    /** =============================================================== **/

    $(".hamburger").on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass("is-active");
    });

});