/**
 * Loading required JS scripts and plugins such as jQuery and Bootstrap
 */

window._ = require('lodash');
window.Popper = require('@popperjs/core');
window.$ = window.jQuery = require('jquery');
require('bootstrap');
require('es6-promise').polyfill();

/**
 * Loading theme JS plugins
 */

// Require input mask using jquery
window.Inputmask = require('inputmask/dist/jquery.inputmask');
require('jquery-validation');
require('@fancyapps/fancybox');
require("./theme-shared");
require("./events");
